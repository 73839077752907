import axios from "axios";
// const hostLocal = "http://192.168.1.72:3001";
const hostLocal = process.env.VUE_APP_URL_API;

let http = axios.create({
  // baseURL: "https://dstones.cosmoscode.com.br/prod/",
  baseURL: hostLocal,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`
  }
});

http.interceptors.request.use(
  function(config) {
    config.headers.Authorization = `Bearer ${
      localStorage.token ? JSON.parse(localStorage.token) : ""
    }`;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
